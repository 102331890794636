<template>
    <div class="row">
        <div v-if="!isEmpty(store.users)" class="col-lg-12">
            <div class="row group-container pt-4 group-main">
                <h5 class="title-group">แคมเปญ</h5>
                <div v-if="!role()" class="col-lg-12 my-3">
                    <div class="btn-group" role="group">
                        <button @click="handle.modalCampaign()" type="button" class="btn btn-secondary">
                            เพิ่มแคมเปญใหม่
                        </button>
                    </div>
                    <hr />
                </div>
                <div class="col-lg-12">
                    <div class="table-responsive">
                        <table class="table-campaign table table-borderless">
                            <thead>
                                <th width="70" style="padding-left: 10px">ตัวแทน</th>
                                <th></th>
                                <th>FYP</th>
                                <th>FYC</th>
                            </thead>
                            <tbody>
                                <template v-for="(user, uuid) in get(store, 'users')">
                                    <tr :key="uuid">
                                        <td style="padding: 10px 11px 4px">
                                            <div
                                                class="avatar"
                                                :style="
                                                    'background-image:url(' +
                                                    get(user, 'avatar', '/media/image/avatar/no-image.png') +
                                                    ')'
                                                "
                                            ></div>
                                            <!-- <img :src="user.avatar || '/media/image/avatar/no-image.png'" alt="" /> -->
                                        </td>
                                        <td>
                                            <p class="pt-1">{{ user.name }}</p>
                                            <p>รหัส: {{ user.username }}</p>
                                        </td>
                                        <td>0</td>
                                        <td>0</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from '@vue/composition-api';

//* store
// import { CAMPAIGN_DISPATCH } from "@/store/service/campaign";
import { USERS_DISPATCH } from '@/store/account/user';

//* components
// import modal from "@/components/modules/modal.module.vue";

//* plugins
import { state, role } from '@/assets/js/tooltify';
import { get, isEmpty } from 'lodash';

export default {
    name: 'cowrie',
    components: {},
    setup(props, Vue) {
        const store = reactive({
            user: state.getters('USER_GETTERS'),
            users: state.getters('USERS_GETTERS'),
        });

        const data = reactive({});

        onMounted(() => {
            Vue.root.$nextTick(() => {
                if (isEmpty(get(store, 'users'))) {
                    state.dispatch(USERS_DISPATCH);
                }
            });
        });

        const handle = {
            modalCampaign: () => {},
        };

        return { store, data, role, handle, get, isEmpty };
    },
};
</script>

<style lang="scss">
.table-campaign {
    border-collapse: separate;
    border-spacing: 0 10px;

    .avatar {
        height: 65px;
        width: 65px;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: inherit;
        background-position: center;
        display: inline-flex;
        border-radius: 8px;
    }

    th {
        padding: 0px;
    }

    tr {
        background: linear-gradient(60deg, #e83464, #d31145);

        td {
            color: white;

            p {
                margin: 0;
            }

            &:first-child {
                border-bottom-left-radius: 8px;
                border-top-left-radius: 8px;
            }

            &:last-child {
                border-bottom-right-radius: 8px;
                border-top-right-radius: 8px;
            }
        }
    }
}
</style>
