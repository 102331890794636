var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    !_vm.isEmpty(_vm.store.users)
      ? _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "row group-container pt-4 group-main" }, [
            _c("h5", { staticClass: "title-group" }, [_vm._v("แคมเปญ")]),
            !_vm.role()
              ? _c("div", { staticClass: "col-lg-12 my-3" }, [
                  _c(
                    "div",
                    { staticClass: "btn-group", attrs: { role: "group" } },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.handle.modalCampaign()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        เพิ่มแคมเปญใหม่\n                    "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("hr"),
                ])
              : _vm._e(),
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c(
                  "table",
                  { staticClass: "table-campaign table table-borderless" },
                  [
                    _vm._m(0),
                    _c(
                      "tbody",
                      [
                        _vm._l(
                          _vm.get(_vm.store, "users"),
                          function (user, uuid) {
                            return [
                              _c("tr", { key: uuid }, [
                                _c(
                                  "td",
                                  { staticStyle: { padding: "10px 11px 4px" } },
                                  [
                                    _c("div", {
                                      staticClass: "avatar",
                                      style:
                                        "background-image:url(" +
                                        _vm.get(
                                          user,
                                          "avatar",
                                          "/media/image/avatar/no-image.png"
                                        ) +
                                        ")",
                                    }),
                                  ]
                                ),
                                _c("td", [
                                  _c("p", { staticClass: "pt-1" }, [
                                    _vm._v(_vm._s(user.name)),
                                  ]),
                                  _c("p", [
                                    _vm._v("รหัส: " + _vm._s(user.username)),
                                  ]),
                                ]),
                                _c("td", [_vm._v("0")]),
                                _c("td", [_vm._v("0")]),
                              ]),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c(
        "th",
        { staticStyle: { "padding-left": "10px" }, attrs: { width: "70" } },
        [_vm._v("ตัวแทน")]
      ),
      _c("th"),
      _c("th", [_vm._v("FYP")]),
      _c("th", [_vm._v("FYC")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }